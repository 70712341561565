.email_repeat {
    display: none !important;
}

a img.img-fill {
    object-fit: cover;
    object-position: 30% 50%;
    height: 100%;
    width: 100%;
}

/*
.ts-control {
    padding: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.ts-control input {
    font-size: 1.0625rem;
}
*/

.smap-suggest {
    margin-top: 55px !important;
}

.smap .card-footer {
    padding: 0 !important;
    border-top: 0 !important;
}

.smap .card-header {
    padding: 0 !important;
    border-bottom: 0 !important;
}

.smap .card-body {
    padding: 0 !important;
}

.area-in, .area-park {
    /*display: inline;*/        
}

.card-body span.area {
    color: #fff;
    margin:0.1em;
    padding-left:0.3em;
    padding-right:0.3em;
    opacity: .6;
}

.ico-card {
    z-index: 500;
    width: 88%;
    padding: 10px;
    border-width: 1px;
}

.pointer {
    cursor: pointer;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader {
    display: none;
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    align-items: center;
    justify-content: center;
}
.loader-active > .loader {
    display: flex;
    animation-name: fadeIn;
    animation-duration: 0.3s;
}
.loader__spinner {
    font-size: 15px;
    display: block;
    border-radius: 50%;
    width: 14em;
    height: 14em;
    margin: 20vh auto;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(51, 94, 234, 0.1);
    border-right: 1.1em solid rgba(51, 94, 234, 0.1);
    border-bottom: 1.1em solid rgba(51, 94, 234, 0.1);
    border-left: 1.1em solid #335eea;
    transform: translateZ(0);
    animation: scaleIn 0.8s 0.3s both ease, spinner 0.8s infinite cubic-bezier(0.575, 0.315, 0.725, 0.965);
}
.loader_show {
    display: block;
}

.takephoto {
    margin: auto;
    margin-top: -8em;
    z-index: 100;
    position: relative;
}

/* hack because click on disabled select no triger event in safari */
#parking_ticket_priceItem:disabled {
    z-index: -1;
}


.logo-selector{
    display: flex;
    align-items: center;
}

.logo-preview{
    height: 50px;
    width: auto;
}

video#qr-reader {
    width: 100%;
    /* add ZOOM to camera of qr-scanner */
    -moz-transform:scale(1.7, 1.7) !important;
    -webkit-transform:scale(1.7, 1.7) !important;
    -o-transform:scale(1.7, 1.7) !important;
    -ms-transform:scale(1.7, 1.7) !important;
    transform:scale(1.7, 1.7) !important;
    max-height: 15em;
}

#qr-status-text {
    margin: auto;
    margin-top: -3em;
    z-index: 100;
    position: relative;
    visibility: hidden;
    width: 80%;
}

.same-width
{
    min-width: 18em;
    max-width: 18em;
}

.toggleMe {
    height: auto;
    transition: height .5s ease;
    overflow: hidden;
}

.toggleMe:not(.toggleMe-active) {
    display: none;
}

.text-left {
    text-align: left;
}

.szn-ending-tooltip {
    display: none;
}
.szn-ending-popup-cover, .szn-ending-popup {
    display: none !important;
}

.non-underline-link {
    text-decoration: none !important;
}